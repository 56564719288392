//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentRegisterBillPage } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'

export default {
  components: {
    STable,
  },
  data() {
    return {
      visible: false,
      flow_urgency_degree: [],
      flow_approval_status: [],
      columns: [
        {
          title: '名稱',
          dataIndex: 'name',
        },

        {
          title: '發文字號',
          dataIndex: 'documentNumber',
        },
        {
          title: '緊急程度',
          dataIndex: 'urgencyDegree',
          scopedSlots: { customRender: 'urgencyDegree' },
        },
        {
          title: '狀態',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' },
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      loadData: (parameter) => {
        return documentRegisterBillPage(parameter).then((res) => res.data)
      },
    }
  },
  mounted() {
    this.getDict()
  },
  methods: {
    addApproval(record) {
      this.visible = false
      this.$emit('addApproval', record)
    },
    open() {
      this.visible = true
    },
    Filter(arr, s) {
      if (s !== 0 && !s) return ''
      return arr.find((item) => Number(item.code) === Number(s))?.value
    },
    getDict() {
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
        this.flow_approval_status = res.data
      })
    },
  },
}
