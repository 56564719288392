//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableModel from '@/views/fileStream/documentApproval/tableModel.vue'
export default {
    components: {
        tableModel,
    },
    data() {
        return {
            entry_src: require('@/assets/img/home/kjrk.png'),
            list: [
                {
                    text: '快速發文',
                    url: '',
                    img: require('@/assets/img/home/ksfw.png'),
                    route: { path: '/fileStreamDocumentSend', query: { todo: 'draftDocumentdeeee' } },
                    storeKey: 'bill/setPostMsg',
                },
                {
                    text: '收文登記',
                    url: '',
                    img: require('@/assets/img/home/swdj.png'),
                    route: { path: '/fileStreamDocumentManage' },
                    storeKey: 'bill/setRegistration',
                },
                {
                    text: '擬稿發文',
                    url: '',
                    img: require('@/assets/img/home/qbng.png'),
                    route: { path: '/fileStreamDocumentSend', query: { todo: 'draftDocument' } },
                    storeKey: 'bill/setPostMsg',
                },
                {
                    text: '發起審批',
                    url: '',
                    img: require('@/assets/img/home/fwng.png'),
                    todo: 'createApproval',
                },
                // {
                //   text: '文書歸檔',
                //   url: '',
                //   img: require('@/assets/img/home/filingOfDocuments.png'),
                //   route: { path: '/fileStreamArchived' }
                // }
            ],
        }
    },
    props: {},
    methods: {
        fastTodo(item) {
            if (item.todo === 'createApproval') {
                this.showModel()
                return
            }
            item.storeKey && this.$store.commit(item.storeKey, true)
            this.$router.push(item.route)
        },
        showModel() {
            this.$refs.tableModel.open()
        },
        addApproval(record) {
            this.$emit('approval', { type: 'approval', id: record.id })
            // this.$refs.fastEntry.open({ type: 'approval', id: record.id })
        },
    },
}
